<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <div class="nav-bottom">
        <div class="footerInfo w1260">
          <div class="top">
             <div><h4>{{ $t('front.gnb.livecasino')}}</h4></div>
             <div><h4>{{ $t('front.gnb.hotelcasino')}}</h4></div>
             <div><h4>{{ $t('front.gnb.slotgame')}}</h4></div>
             <div>
                <h4>{{ $t('front.stributor.dnws')}}</h4>
                <ul>
                   <li @click="goPageByName('charge')">{{ $t('front.common.deposit')}}</li>
                   <li @click="goPageByName('exchange')">{{ $t('front.common.withdrawal')}}</li>
                </ul>
             </div>
             <div>
                <h4>{{ $t('front.common.cscenter')}}</h4>
                <ul>
                   <li @click="goPageByName('notice')">{{ $t('front.common.notice')}}</li>
                   <li @click="goPageByName('qna')">{{ $t('front.common.qna')}}</li>
                   <li @click="goPageByName('faq')">{{ $t('front.common.faq')}}</li>
                </ul>
             </div>
             <div>
                <h4>{{ $t('front.common.mypage')}}</h4>
                <ul>
                   <li @click="goPageByName('bettingList')">{{ $t('front.mypage.betList')}}</li>
                   <li @click="goPageByName('point')">{{ $t('front.mypage.pointList')}}</li>
                   <li @click="goPageByName('message')">{{ $t('front.mypage.message')}}</li>
                   <li @click="goPageByName('info')">{{ $t('front.mypage.memInfoModify')}}</li>
                </ul>
             </div>
             <div>
                <h4>{{ $t('front.mypage.community')}}</h4>
                <ul class="sns" v-if="siteOption">
                 <li @click="siteOption['sns2Link']">
                   <img src="@/assets/img/telegram.svg" />
                   <span><em>{{siteOption['sns2Id']}}</em></span>
                 </li>
                 <li @click="siteOption['sns1Link']">
                   <img src="@/assets/img/icon_kakao.svg" />
                   <span><em>{{siteOption['sns1Id']}}</em></span>
                 </li>
                 <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
                   <img src="@/assets/img/zalo.svg" />
                   <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
                 </li>
               </ul>
             </div>
          </div>
          <div class="bottom">
            <span class="copyright">Copyright 2023 Gold members. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.footerInfo ul li{cursor: pointer;}
</style>
